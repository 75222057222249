import React from 'react';
import { ButtonWhite } from "../components/ButtonWhite"
import { mdiEmail } from "@mdi/js"
import Icon from "@mdi/react"

function Newsletter() {
  return (
    <div className="mb-8"
         data-aos="fade-up">
      <div className="mx-auto max-w-7xl sm:px-8 px-0">
        <div className="relative isolate overflow-hidden bg-neutral-800 px-6 py-16 sm:rounded-4xl text-center">
          <p
            className="mt-2 text-5xl sm:text-6xl font-display font-medium tracking-tight text-white text-center">
            Tell us about your project</p>
          <ButtonWhite to="/contact" href="/contact" className="mt-6 flex items-center">
            <Icon
              path={mdiEmail}
              className="inline mr-2 h-5 w-5"
            />
            Contact Us</ButtonWhite>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
