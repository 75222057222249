import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import image from './../images/heromain2.jpg';

export const SEO = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, keywords } = useSiteMetadata()

  const seo = {
    title: `${title} - ${defaultTitle}` || defaultTitle,
    description: description || defaultDescription,
    image: image,
    url: `${siteUrl}/${pathname || ``}`,
    keywords: keywords,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords}/>
      <meta name="image" content={seo.image} />

      <meta property="og:url" content={seo.url}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={seo.title}/>
      <meta property="og:description" content={seo.description}/>
      <meta property="og:image" content={seo.image}/>

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {children}
    </>
  )
}


