import React from 'react';
import Icon from '@mdi/react';
import {
  mdiHandshake,
  mdiPalette,
  mdiCellphone,
  mdiCurrencyUsd, mdiCogs, mdiAccountGroup, mdiCheck, mdiEmail, mdiCursorDefaultClick
} from "@mdi/js"
import { Button } from "../components/Button"
import { Container } from "../components/Container"

export default function Perks() {
  const features = [
    {
      name: 'Business Applications',
      description: 'We are experienced in creating enterprise applications. Our apps are used by hundreds of users from multiple B2B clients.',
      icon: mdiHandshake,
    },
    {
      name: 'Landing Pages',
      description: 'Need a website for your business? We can provide you with a high quality website in a short period of time.',
      icon: mdiPalette,
    },
    {
      name: 'Mobile Applications',
      description: 'Applications that works both on Android and iOS. Reach out to your customers and let them use your services from the phone.',
      icon: mdiCellphone,
    },
    {
      name: 'Automations',
      description: 'We develop tools that help automate repetitive processes in your company, so your employees will have more time for more productive tasks.',
      icon: mdiCogs,
    },
    {
      name: 'Consulting',
      description: 'Want to learn how technology can help your business grow? We will examine how your business works and look for places to implement improvements such as automation, AI or analytics.',
      icon: mdiAccountGroup,
    },
  ]
  return (
    <>
      <Container className="py-24 bg-transparent rounded-b-4xl">
        <div
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-4 sm:gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-ndna">Explore our services</h2>
            <p
              className="mt-2 text-5xl sm:text-6xl font-display font-medium tracking-tight text-gray-900">What do we do?</p>
            <div className="mt-4 mb-4 text-gray-600">
              Business applications
              <span
                className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-900 mx-2">•</span>Landing
              Pages
              <span
                className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-900 mx-2">•</span>
              Automations
            </div>
            <Button to="/contact" href="/contact" className="mt-4 hidden sm:inline-flex bg-ndna hover:text-ndna hover:border-ndna flex items-center">
              <Icon
                path={mdiEmail}
                className="inline mr-2 h-5 w-5"
              />
              Contact Us
            </Button>
          </div>
          <dl
            className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature, index) => (
              <div key={feature.name} className="relative pl-9"
                   data-aos="fade-up"
                   data-aos-delay={`${index * 100}`}>
                <dt className="font-semibold text-gray-900">
                  <Icon path={feature.icon}
                        className="absolute left-0 top-1 h-5 w-5 text-ndna"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
                {/*<Button className="mt-2 py-2 px-4 text-xs">*/}
                {/*  <Icon*/}
                {/*    path={mdiCursorDefaultClick}*/}
                {/*    className="inline mr-2 h-4 w-4"*/}
                {/*  />*/}
                {/*  Learn more*/}
                {/*</Button>*/}
              </div>
            ))}
          </dl>
        </div>
      </Container>
    </>
  );
}
